<!-- 集团看板 -->
<template>
    <div id="groupKanban">
        <!-- 左侧四个模板 -->
        <div class="groupKanban-left">
            <div class="groupKanban-left-top">
                <!-- 集团整体用电量 -->
                <div class="container">
                    <div class="title">
                        <img src="../../../../public/images/kanban/electricity.png" />
                        <!-- <span>集团整体用电量</span> -->
                        <span>{{ $t('Kanban.a58')}}</span>
                    </div>
                    <div class="cardFont">
                        <div class="interlacing">
                            <a-row>
                                <a-col :span="10" class="cardFont-left">
                                    {{ $t('Kanban.a1')}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                     {{data.total.yearValue}}kWh
                                </a-col>
                                <a-col :span="10" class="cardFont-left">
                                    {{data.total.rateLabel}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                    <span v-if="data.total.yearRate<0" style="color: #20b541">
                                        {{data.total.yearRate*-1}}%
                                        <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                    </span>
                                    <span v-else-if="data.total.yearRate===0" style="color: #e8e8e8">
                                        {{data.total.yearRate}}%
                                        <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                    </span>
                                    <span v-else style="color: #f36503">
                                        {{data.total.yearRate}}%
                                        <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                    </span>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="interlacing">
                            <a-row>
                                <a-col :span="10" class="cardFont-left cardFont-left">
                                    {{ $t('Kanban.a2')}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                     {{data.total.monthValue}}kWh
                                </a-col>
                                <a-col :span="10" class="cardFont-left">
                                    {{data.total.rateLabel}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                    <span v-if="data.total.monthRate<0" style="color: #20b541">
                                        {{data.total.monthRate*-1}}%
                                        <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                    </span>
                                    <span v-else-if="data.total.monthRate===0" style="color: #e8e8e8">
                                        {{data.total.monthRate}}%
                                        <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                    </span>
                                    <span v-else style="color: #f36503">
                                        {{data.total.monthRate}}%
                                        <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                    </span>
                                </a-col>
                            </a-row>
                        </div>
                        <div>
                            <a-row>
                                <a-col :span="10" class="cardFont-left cardFont-left">
                                   {{ $t('Kanban.a3')}}
                                </a-col>
                               <a-col :span="14" class="cardFont-right">
                                     {{data.total.dayValue}}kWh
                                </a-col>
                                <a-col :span="10" class="cardFont-left">
                                    {{data.total.rateLabel}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                    <span v-if="data.total.dayRate<0" style="color: #20b541">
                                        {{data.total.dayRate*-1}}%
                                        <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                    </span>
                                    <span v-else-if="data.total.dayRate===0" style="color: #e8e8e8">
                                        {{data.total.dayRate}}%
                                        <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                    </span>
                                    <span v-else style="color: #f36503">
                                        {{data.total.dayRate}}%
                                        <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                    </span>
                                </a-col>
                            </a-row>
                        </div>
                    </div>
                </div>
                <!-- 集团整体碳排放量 -->
                <div class="container">
                    <div class="title">
                        <img src="../../../../public/images/kanban/recycle.png" />
                        <!-- <span>集团整体碳排放量</span> -->
                        <span>{{ $t('Kanban.a59')}}</span>
                    </div>
                    <div class="cardFont">
                        <div class="interlacing">
                            <a-row>
                                <a-col :span="10" class="cardFont-left">
                                    {{ $t('Kanban.a1')}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                     {{data.carbon.yearCarbonValue}}kg
                                </a-col>
                                <a-col :span="10" class="cardFont-left">
                                    {{data.carbon.rateLabel}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                    <span v-if="data.carbon.yearCleanRate<0" style="color: #20b541">
                                        {{data.carbon.yearCleanRate*-1}}%
                                        <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                    </span>
                                    <span v-else-if="data.carbon.yearCleanRate===0" style="color: #e8e8e8">
                                        {{data.carbon.yearCleanRate}}%
                                        <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                    </span>
                                    <span v-else style="color: #f36503">
                                        {{data.carbon.yearCleanRate}}%
                                        <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                    </span>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="interlacing">
                            <a-row>
                                <a-col :span="10" class="cardFont-left cardFont-left">
                                    {{ $t('Kanban.a2')}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                     {{data.carbon.monthCarbonValue}}kg
                                </a-col>
                                <a-col :span="10" class="cardFont-left">
                                    {{data.carbon.rateLabel}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                    <span v-if="data.carbon.monthCleanRate<0" style="color: #20b541">
                                        {{data.carbon.monthCleanRate*-1}}%
                                        <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                    </span>
                                    <span v-else-if="data.carbon.monthCleanRate===0" style="color: #e8e8e8">
                                        {{data.carbon.monthCleanRate}}%
                                        <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                    </span>
                                    <span v-else style="color: #f36503">
                                        {{data.carbon.monthCleanRate}}%
                                        <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                    </span>
                                </a-col>
                            </a-row>
                        </div>
                        <div>
                            <a-row>
                                <a-col :span="10" class="cardFont-left cardFont-left">
                                   {{ $t('Kanban.a3')}}
                                </a-col>
                               <a-col :span="14" class="cardFont-right">
                                     {{data.carbon.dayCarbonValue}}kg
                                </a-col>
                                <a-col :span="10" class="cardFont-left">
                                    {{data.carbon.rateLabel}}
                                </a-col>
                                <a-col :span="14" class="cardFont-right">
                                    <span v-if="data.carbon.dayCleanRate<0" style="color: #20b541">
                                        {{data.carbon.dayCleanRate*-1}}%
                                        <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                    </span>
                                    <span v-else-if="data.carbon.dayCleanRate===0" style="color: #e8e8e8">
                                        {{data.carbon.dayCleanRate}}%
                                        <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                    </span>
                                    <span v-else style="color: #f36503">
                                        {{data.carbon.dayCleanRate}}%
                                        <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                    </span>
                                </a-col>
                            </a-row>
                        </div>
                    </div>
                </div>
            </div>
            <div class="groupKanban-left-bottom">
                <!-- KPI -->
                <div class="container-kpi">
                    <div class="containerkpi">
                        <div :id="echartsId1" :style="{ width: '100%', height: '100%' }"></div>
                    </div>
                    <div class="containerkpi">
                        <div :id="echartsId2" :style="{ width: '100%', height: '100%' }"></div>
                    </div>
                    <div class="standard">
                        <span>
                            <!-- 已达标{{data.kpi.kpiAimTargrt}}家 -->
                            <span>{{ getLabel(data.kpi.kpiAimTargrt,'Kanban.a60')}}</span>
                            <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                        </span>
                        <span>
                            <!-- 未达标{{data.kpi.unkpiAimTargrt}}家 -->
                             <span>{{ getLabel(data.kpi.unkpiAimTargrt,'Kanban.a61')}}</span>
                            <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                        </span>
                    </div>
                </div>
                <!-- 用电总量排名 -->
                <div class="electric">
                    <div :id="echartsId3" :style="{ width: '100%', height: '100%' }"></div>
                </div>
            </div>
        </div>
        <!-- 右侧两个模版 -->
        <div class="groupKanban-right">
            <div class="groupKanban-right-container">
                <div :id="echartsId4" :style="{ width: '100%', height: '100%' }"></div>
            </div>
            <div class="groupKanban-right-container">
                <div :id="echartsId5" :style="{ width: '100%', height: '100%' }"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { EleResize } from '../../../utils/esresize'
const colors = ['#7682ce','#a0a9ec','#c3caf8','#e8eaff','#ed6f72','#fbdd9f','#94cb7a','#5673c3','#fb8e91','#feebc3','#b4e79c','#7590e1','#fdc9cb']

export default {
    props: {
        data: {
            type: Object,
            default:{
                branchPosition:{
                    branchs:[],
                    colors:[],
                    values:[]
                },
                branchSection:{
                    series:[],
                    source:[]
                },
                carbon:{
                    id: 0,
                    modual: 0,
                    yearCarbonValue: 0,
                    monthCarbonValue: 0,
                    dayCarbonValue: 0,
                    yearLastCarbonValue: 0,
                    monthLastCarbonValue: 0,
                    dayLastCarbonValue: 0,
                    yearCleanCarbonValue: 0,
                    monthCleanCarbonValue: 0,
                    dayCleanCarbonValue: 0,
                    yearCleanLastCarbonValue: 0,
                    monthCleanLastCarbonValue: 0,
                    dayCleanLastCarbonValue: 0,
                    yearCleanRate: 0,
                    monthCleanRate: 0,
                    dayCleanRate: 0
                },
                kpi:{
                    id: 0,
                    modual: 0,
                    monthValue: 0,
                    yearAimRate: 0,
                    monthAimRate: 0,
                    yearKPIValue: 0,
                    yearAimValue: 0,
                    monthAimValue: 0,
                    kpiAimTargrt: 0,
                    unkpiAimTargrt: 0
                },
                statics:{
                    series:[],
                    legend:[],
                    cates:[],
                },
                total:{
                    id: 0,
                    modual: 0,
                    yearValue: 0,
                    monthValue: 0,
                    dayValue: 0,
                    yearLastValue: 0,
                    monthLastValue: 0,
                    dayLastValue: 0,
                    yearRate: 0,
                    monthRate: 0,
                    dayRate: 0
                }
            },
        },
    },
    data(){
        let that = this;
        let top = '2%';
        return{
            // 集团月度KPI
            gaugeoptions: {
                title: {
                    text: this.$t('Kanban.a62')+' (kWh)',//'集团月度KPI',
                    left: "5%",
                    top: top,
                    textStyle: {
                        fontSize: '0.15rem',
                        color:'#000000',
                        fontWeight: '300',
                        fontFamily: 'Microsoft YaHei'
                    }
                },
                series: [
                    {
                        type: "gauge",
                        startAngle: 90,
                        endAngle: -270,
                        min: 0,
                        max: 100,
                        center: ["50%", "55%"],
                        radius: "65%",
                        itemStyle: {
                            color: "#7580d0",
                            shadowColor: "#7580d0",
                            shadowBlur: 10,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                        },
                        progress: {
                            //上层进度条
                            show: true,
                            width: 20,
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        axisLine: {
                            //下层总量条
                            lineStyle: {
                                width: 20,
                                color: [[1, "#f3f4f9"]],
                            },
                        },
                        //刻度样式
                        axisTick: {
                            show: false,
                        },
                        //分隔线
                        splitLine: {
                            show: false,
                        },
                        //刻度标签
                        axisLabel: {
                            show: false,
                        },
                        title: {
                            show: false,
                        },
                        detail: {
                            show: true,
                            offsetCenter: [2, 2],
                            formatter: function (value) {
                                return "{value|" + value.toFixed(2) + "%}";
                            },
                            rich: {
                                value: {
                                fontSize: '0.12rem',
                                fontWeight: "bolder",
                                color: "#7580d0",
                                },
                            },
                        },
                        data: [
                            {
                                value: 90,
                            },
                        ],
                    },
                ],
            },
            // 各公司KPI完成情况
            option:{
                color:['#c3c9fb','#747ed3',],
                title: {
                    text: this.$t('Kanban.a63'),//'各公司KPI完成情况',
                    left: "5%",
                    top: top,
                    textStyle: {
                        fontSize: '0.15rem',
                        color:'#000000',
                        fontWeight: '300',
                        fontFamily: 'Microsoft YaHei'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: '0px',
                    left: 'center'
                },
                series: [
                    {
                    // name: 'Access From',
                    type: 'pie',
                    radius: ['35%', '65%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        // borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                        show: true,
                        fontSize: '24',
                        fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 10, name: this.$t('Kanban.a64') },
                        { value: 1 , name: this.$t('Kanban.a65') },
                    ]
                    }
                ]
            },
            // 峰谷分析
            sectionOption:{
                color:['#5a68ce','#818cd9','#a9b0e5','#d0d4f1','#d0d4f6'],
                title: {
                    text: this.$t('Kanban.a66')+"  (kWh)",//'峰谷分析',
                    left: "2%",
                    top: top,
                    textStyle: {
                        fontSize: '0.15rem',
                        color:'#000000',
                        fontWeight: '300',
                        fontFamily: 'Microsoft YaHei'
                    }
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                legend: {
                    bottom: "3px",
                },
                grid: {
                    top:'15%',
                    left: "15px",
                    right: "15px",
                    bottom: "30px",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    axisLabel:{
                        show:true,
                        interval:0,
                    }
                },
                yAxis: {
                    type: "value",
                    name: '',
                    axisLine: {
                      show: true,
                      lineStyle: {
                        color: '#979797'
                      }
                    }
                },
                series: this.data.branchSection.series,
                dataset: {
                    source:this.data.branchSection.source,
                },
            },
            // 用能对比
            option2:{
                title: {
                    text: this.$t('Kanban.a67')+"  (kWh)",//'用能对比',
                    left: "2%",
                    top: top,
                    textStyle: {
                        fontSize: '0.15rem',
                        color:'#000000',
                        fontWeight: '300',
                        fontFamily: 'Microsoft YaHei'
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: this.data.statics.legend,
                    bottom: "3px",
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '30px',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.data.statics.cates
                },
                yAxis: {
                    type: 'value'
                },
                series:  this.data.statics.series
            },
            // 公司用电总量及排名
            positionOption: {
                title: {
                    text: this.$t('Kanban.a68')+"  (kWh)",//'用电排名',
                    left: "2%",
                    textStyle: {
                        fontSize: '0.15rem',
                        color:'#000000',
                        fontWeight: '300',
                        fontFamily: 'Microsoft YaHei'
                    }
                },
                grid: {
                    top:'10%',
                    left: "15px",
                    bottom: "0px",
                    right:'30%',
                    containLabel: true,
                },
                xAxis: {
                    show: false,
                    type: "value",
                },
                yAxis: {
                    type: "category",
                    top:'10%',
                    nameLocation: "start",
                    nameTextStyle: {
                        align: "center",
                        verticalAlign: "middle",
                    },
                    inverse: true,
                    data: ["大石工厂", "成都工厂", "上海工厂", "天津工厂", "扶梯工厂", "电机工厂", "日立楼宇", "科学城食堂","大石工厂", "成都工厂", "上海工厂", "天津工厂", "扶梯工厂", "电机工厂", "日立楼宇", "科学城食堂"],
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                },
                series: [
                    {
                        realtimeSort: true,
                        type: "bar",
                        itemStyle: {
                            color: function (param) {
                                return that.positionColors[param.dataIndex] || "#5470c6";
                            },
                        },
                        barWidth:24,
                        data: [12352,93452,72345,523452,423452,323452,223452,123452,12352,93452,72345,523452,423452,323452,223452,123452],
                        label: {
                            show: true,
                            position: "right",
                        },
                    },
                ],
            },
            positionColors:[],
        }
    },
    mounted(){
        setTimeout(() => {
            this.drawLine()
            this.drawLine1()
            this.drawLine2()
            this.drawLine3()
            this.drawLine4()
        }
        ,100);
    },
    computed: {
        echartsId1() {
            return "echartsId1" + Math.floor((Math.random() * 10000) + 1);
        },
        echartsId2() {
            return "echartsId2" + Math.floor((Math.random() * 10000) + 1);
        },
        echartsId3() {
            return "echartsId3" + Math.floor((Math.random() * 10000) + 1);
        },
        echartsId4() {
            return "echartsId4" + Math.floor((Math.random() * 10000) + 1);
        },
        echartsId5() {
            return "echartsId5" + Math.floor((Math.random() * 10000) + 1);
        },

    },
    methods:{
        getLabel(num,key){
            let lab = this.$t(key);
            let msg = lab.replace('${num}',num);
            return msg;
        },
        drawLine() {
            this.gaugeoptions.series[0].data[0].value = this.data.kpi.kpiAimRate;
            if(this.data.kpi.reportType==1){
                this.gaugeoptions.title.text = this.$t('Kanban.a84')+' (kWh)';
            }else if(this.data.kpi.reportType==3){
                this.gaugeoptions.title.text = this.$t('Kanban.a85')+' (kWh)';
            }else{
                this.gaugeoptions.title.text = this.$t('Kanban.a62')+' (kWh)';
            }
            if(this.data.kpi.kpiAimRate>100){

                this.gaugeoptions.series[0].itemStyle.color =  "#FF0000";
                this.gaugeoptions.series[0].itemStyle.shadowColor = "#FF0000";
                this.gaugeoptions.series[0].detail.rich.value.color =  "#FF0000";
            }

            let id = this.echartsId1;
            let myChart = this.$echarts.init(document.getElementById(id));
            let resizeDiv = document.getElementById(id);
            myChart.setOption(this.gaugeoptions);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        drawLine1() {
            this.option.series[0].data[0].value = this.data.kpi.kpiAimTargrt;
            this.option.series[0].data[1].value = this.data.kpi.unkpiAimTargrt;
            let id = this.echartsId2;
            let myChart = this.$echarts.init(document.getElementById(id));
            let resizeDiv = document.getElementById(id);
            myChart.setOption(this.option);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        drawLine2() {
            this.positionColors = this.data.branchPosition.colors;
            this.positionOption.yAxis.data = this.data.branchPosition.branchs;
            this.positionOption.series[0].data = this.data.branchPosition.values;
            let id = this.echartsId3;
            let myChart = this.$echarts.init(document.getElementById(id));
            let resizeDiv = document.getElementById(id);
            myChart.setOption(this.positionOption);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        drawLine3() {
            let id = this.echartsId4;
            let myChart = this.$echarts.init(document.getElementById(id));
            let resizeDiv = document.getElementById(id);
            myChart.setOption(this.sectionOption);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        drawLine4() {
            let id = this.echartsId5;
            let myChart = this.$echarts.init(document.getElementById(id));
            let resizeDiv = document.getElementById(id);
            myChart.setOption(this.option2);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
    }
}
</script>

<style scoped>
#groupKanban{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    /* font-family: "Microsoft YaHei"; */
}
img{
    width: 0.3rem;
    margin-right: 0.1rem;
}
.groupKanban-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(40% - 0.05rem);
    height: 100%;
    min-width: 400px;
}
.groupKanban-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:calc(60% - 0.05rem);
    min-width: 600px;
}
.groupKanban-left-top{
    display: flex;
    justify-content: space-between;
    height: 2.05rem;
}
.groupKanban-left-bottom{
    display: flex;
    justify-content: space-between;
    height: calc(100% - 2.15rem);
}
.container{
    border-radius: 0.03rem;
    background-color: #fff;
    box-shadow: 0 0px 10px #bdbcbc;
    width: calc(50% - 0.05rem);
    height: 100%;
    padding: 0.1rem;
    overflow: hidden;
}
.title{
    font-size: 0.14rem;
    margin-bottom: 0.05rem;
}
.cardFont{
    padding-left: 0.08rem;
}
.cardFont-left{
    font-size: 0.12rem;
    color: #a0a0a0;
    white-space: nowrap;
}
.cardFont-right{
    text-align: right;
    font-size: 0.14rem;
    color: #3e3d3b;
}
.interlacing{
    margin-bottom: 0.05rem;
}
.groupKanban-right-container{
    height: calc(50% - 0.05rem);
    width: 100%;
    border-radius: 0.03rem;
    background-color: #fff;
    box-shadow: 0 0px 10px #bdbcbc;
}
.container-kpi{
    display: flex;
    flex-direction: column;
    width: calc(50% - 0.05rem);
    background-color: #fff;
    box-shadow: 0 0px 10px #bdbcbc;
    border-radius: 0.03rem;
    overflow: hidden;
}
.containerkpi{
    width: 100%;
    height: calc(50% - 0.15rem);
}
.electric{
    border-radius: 0.03rem;
    background-color: #fff;
    box-shadow: 0 0px 10px #bdbcbc;
    width: calc(50% - 0.05rem);
    height: 100%;
    /* overflow: hidden; */
    padding: 0.05rem;
}
.standard{
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    padding: 0.05rem 0.3rem 0rem 0.3rem;
}
</style>