<!--新能源看板-->
<template>
  <div id="newSiteKanban" v-loading="loading"  :element-loading-text="$t('alarm.a0')">
    <div class="header">
        <div class="header-left">
            <div class="header-name">
                {{ name }}
            </div>
            <div class="weather">
                <table>
                    <tr>
                        <td>
                             <i :class="getWeatherClz(weather.icon)" :style="{ fontSize: '0.32rem',color: '#7682CE' }"></i>
                        </td>
                        <td class="tds">
                            <div style="font-size: 0.14rem;">{{weather.temp}}℃ </div>
                            <div style="font-size: 0.10rem;">{{weather.text}}</div>
                        </td>
                        
                        <td class="tds">
                            <div style="font-size: 0.14rem;">{{weather.pressure}}hPa </div>
                            <div style="font-size: 0.10rem;color:#a0a0a0;">{{$t('Kanban.a69')}}</div>
                        </td>
                        <td class="tds">
                            <div style="font-size: 0.14rem;">{{weather.humidity}}% </div>
                            <div style="font-size: 0.10rem;color:#a0a0a0;">{{$t('Kanban.a74')}}</div>
                        </td>
                        <td class="tds">
                            <div style="font-size: 0.14rem;">{{weather.precip}}mm </div>
                            <div style="font-size: 0.10rem;color:#a0a0a0;">{{$t('Kanban.a70')}}</div>
                        </td>
                         <td class="tds">
                            <div style="font-size: 0.12rem;"> {{getWindDir(weather.windDir)}} {{getWindScale(weather.windScale)}}</div>
                            <div style="font-size: 0.12rem;text-align: right;">{{weather.windSpeed}}km/h</div>
                        </td>
                        <td>
                            <a-icon type="environment" class="icon" :style="{ fontSize: '0.26rem',color: '#7682CE',margin: '0 0 0 10px' }"/>
                        </td>
                        <td class="tds">
                            <div style="font-size: 0.14rem;"> {{weather.address}} </div>
                            <div style="font-size: 0.10rem;text-align: right;">
                                 {{displayTime.format('LLLL')}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="header-right">
             <a-tree-select
                v-model="kanban"
                style="width: 100%;max-width:4rem;min-width:2rem;font-size:0.11rem;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto',fontSize:'0.12rem' }"
                :tree-data="energySiteList"
                :replace-fields="replaceFields"
                :placeholder="placeholder"
                allow-clear
                tree-default-expand-all
                @change="treeSelectChange" @select="treeSelected">
            </a-tree-select>
            <!-- 查询 -->
            <a-button type="primary" style="margin-left:5px;font-size:0.115rem;" icon="search" @click="search">{{$t('energy.search')}}</a-button>
        </div>
    </div>

    <div :class="level==='client'?'main':'kanban'">
        <div v-if="level==='client' && energyKanBanData" class="module">
            <my-groupKanban :data="energyKanBanData" />
        </div>
        <div v-else-if="(level==='sitegr' || level==='site') && energyKanBanData" class="module">
            <my-globalFunction :list="energyKanBanData" />
        </div>
        <div v-else>
            <a-empty>
                <span  slot="description" v-if="msg!=''"> {{msg}} </span>
                <a-button type="primary" icon="arrow-right" @click="gotoPage"
                    v-if="err==='2218' ||  err==='2217' ||  err==='2216' || (err==='2011' && level==='site')" >
                    {{$t('Kanban.a37')}}
                </a-button>
            </a-empty>
        </div>
    </div>
    <div class="floor" v-if="level!='client'">
        <div class="floor-left">
            <div class="img-container">
                <a-badge :offset="[-10,0]" :count="alarmCount.seriousWarning.sumCount">
                    <img src="../../../public/images/kanban/seriousness.png" />
                </a-badge>
                <!-- 严重警告 -->
                <span>{{$t('Kanban.a28')}}</span>
            </div>
            <div class="img-container">
                <a-badge :offset="[-10,0]" :count="alarmCount.seriousWarning.restoredCount">
                    <img src="../../../public/images/kanban/recover.png" />
                </a-badge>
                <!-- <span>已恢复</span> -->
                <span>{{$t('Kanban.a30')}}</span>
            </div>
            <div class="img-container interval">
                <a-badge :offset="[-10,0]" :count="alarmCount.seriousWarning.notRecoveredCount">
                    <img src="../../../public/images/kanban/unrecovered-seriousness.png" />
                </a-badge>
                <!-- <span>未恢复</span> -->
                <span>{{$t('Kanban.a31')}}</span>
            </div>
            <div class="img-container">
                <a-badge :offset="[-10,0]" :count="alarmCount.generalWarning.sumCount">
                    <img src="../../../public/images/kanban/common.png" />
                </a-badge>
                <!-- <span>一般警告</span> -->
                <span>{{$t('Kanban.a29')}}</span>
            </div>
            <div class="img-container">
                <a-badge :offset="[-10,0]" :count="alarmCount.generalWarning.restoredCount">
                    <img src="../../../public/images/kanban/recover.png" />
                </a-badge>
                <!-- <span>已恢复</span> -->
                <span>{{$t('Kanban.a30')}}</span>
            </div>
            <div class="img-container">
                <a-badge :offset="[-10,0]" :count="alarmCount.generalWarning.notRecoveredCount">
                    <img src="../../../public/images/kanban/unrecovered-common.png" />
                </a-badge>
                <!-- <span>未恢复</span> -->
                <span>{{$t('Kanban.a31')}}</span>
            </div>

        </div>
        <div class="floor-right">
         <!-- 设置 -->
            <!-- <a-button type="primary" style="margin-left:5px;" icon="setting" @click="goSetting">{{$t('Kanban.a38')}}</a-button> -->
        </div>
    </div>
     <div class="client-floor"  @click="goSetting" :title="$t('Kanban.a38')">
         <a-icon type="setting" :style="{fontSize :'0.18rem',color:'#FFF',margin:'0.04rem'}" />
     </div>
  </div>
</template>

<script>
import groupKanban from './components/groupKanban'              // 集团看板
import globalFunction from './components/globalFunction.vue'    // 全功能看板
// import electricEnergy from './components/electricEnergy.vue'    // 单电能看板
// import noPhotovoltaic from './components/noPhotovoltaic.vue'    // 无光伏看板
// import noWater from './components/noWater.vue'                  // 无水能看板
import moment from "moment";
import { getEnergySiteList,getEnergyKanBanData,getEnergyKanBanWeather,getEnergyKanBanAlarmCount } from "../../api/energy";

const weather={
                obsTime: "",
                temp: "0",
                feelsLike: "0",
                icon: "100",
                text: "晴",
                wind360: "0",
                windDir: "--",
                windScale: "--",
                windSpeed: "",
                humidity: "--",
                precip: "",
                pressure: "--",
                vis: "",
                cloud: "",
                dew: "",
                address: ""
            }
const alarmCount = {
    seriousWarning: {
      sumCount: 0,
      restoredCount: 0,
      notRecoveredCount: 0
    },
    generalWarning: {
      sumCount: 0,
      restoredCount: 0,
      notRecoveredCount: 0
    }
  }
export default {
    components:{
        'my-groupKanban':groupKanban,
        'my-globalFunction':globalFunction,
        // 'my-electricEnergy':electricEnergy,
        // 'my-noPhotovoltaic':noPhotovoltaic,
        // 'my-noWater':noWater
    },
    data(){
        return{
            loading:false,
            value:'1',
            kanban:'',
            energySiteList:[],
            replaceFields:{children:'children', title:'name', key:'key', value: 'key' },
            level:'',
            id:'',
            energyKanBanData:undefined,
            msg:'',
            err:'',
            timer:'',
            exctime:moment(),
            displayTime:moment(),
            name:'',
            address:'',
            placeholder:'',
            weather:weather,
            alarmCount:alarmCount,
            option:undefined,
            userId:0,
        }
    },
    mounted(){
        this.getEnergySiteList();
        let that = this;
        let user = JSON.parse(sessionStorage.getItem('user'));
        console.log('user:',user)
        this.userId = JSON.parse(sessionStorage.getItem('user')).id;

        this.timer = setInterval(()=>{
            let now = new moment();
            let time = now.format('mm');
            let mstime = now.diff(that.exctime,'minutes');
            let mod = parseInt(time);// % 60;
            // console.log("time & mstime & mod",time,mstime,mod);
            if(mod === 20 && mstime>1){
                that.exctime = now;
                that.search();
            }
            that.displayTime = now;
        },10*1000);
    },
    beforeDestroy(){
        clearInterval(this.timer);
    },
    methods:{
        treeSelected(value, node){
            console.log("value & value",value);
            
        },
        treeSelectChange(value, label){
            this.name = label[0];
            this.search();
        },
        gotoPage(){
            this.option = this.option?this.option:this.getKanBanOption(this.energySiteList,this.kanban);
            console.log("this.option",this.kanban,this.option);
            if(this.err ==='2218' ||  this.err==='2217' ||  this.err==='2216' ){
                this.goSetting();
            }else if( this.err==='2011'){

                if(this.option && this.option.rootId && this.option.buildId){
                    let oneNav = 0;
                    for (let i = 0; i < this.$store.getters.oneMenu.length; i++) {
                        const element = this.$store.getters.oneMenu[i];
                        if(element.id==this.option.rootId){
                            oneNav = i;
                            break;
                        }
                    }
                    
                    let tmenu = [];
                    let twoNav = 0;
                    for (let i = 0; i < this.$store.getters.twoMenu.length; i++) {
                        const element = this.$store.getters.twoMenu[i];
                        if(element.id==this.option.buildId){
                            tmenu = element.siteList;
                            twoNav = i;
                            break;
                        }
                    }

                   
                
                    this.$store.commit("menu/HANDLE_ONEMENU", oneNav);
                    this.$store.commit("menu/HANDLE_TWOMENU", twoNav);
                    // this.$store.commit("menu/SITE_MENU", threeNav);
                    sessionStorage.setItem("oneNav", oneNav);
                    sessionStorage.setItem("twoNav", twoNav);

                    let threeNav = 0;
                    console.log('tmenu',tmenu);
                    console.log('this.$store.getters.threeMenu',this.$store.getters.threeMenu);
                    for (let i = 0; i < this.$store.getters.threeMenu.length; i++) {
                        const element = this.$store.getters.threeMenu[i];
                        if(element.id === this.option.no && (element.siteServiceTypeID === 8 ||  element.siteServiceTypeID === 9)){
                            threeNav = i;
                            break;
                        }
                    }

                    this.$router.push({
                        path: "/homepage/energy/setup/eneUseKind",
                        query: { id: this.option.no, Code: threeNav },
                    });
                }

               
            }
        },
        goSetting(){
            localStorage.setItem('kanbanValue'+this.userId,this.kanban);
            this.$router.push({
                path: "energyKanBanSetting"
            });
        },
        getEnergySiteList(){
            this.loading = true;
            getEnergySiteList()
            .then((res) => {
                console.log('GetEnergySiteList result',res);
                this.energySiteList = res.data;
                this.loading = false;
                console.log('EnergySiteList result',this.energySiteList);
                this.kanban = localStorage.getItem('kanbanValue'+this.userId)?localStorage.getItem('kanbanValue'+this.userId) : this.energySiteList[0].key;
                // for(let i=0;i<20;i++){
                //     }
                this.search();
                
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        getKanBanOption(options,key){
            if(options && key){
                for (let i = 0; i < options.length; i++) {
                    let element = options[i];
                    if(element.key === key){
                        return element;
                    }

                    if(element.children){
                        let ele = this.getKanBanOption(element.children,key);
                        if(ele){
                            return ele;
                        }
                    }
                    
                }
            }
            return null;
        },
        search(){
            this.msg = '';
            this.err = '';
            if(!this.kanban){
                this.$message.error(this.$t('Kanban.a50'));
                return;
            }
            localStorage.setItem('kanbanValue'+this.userId,this.kanban);
            this.$route.query.id = this.kanban;
            let vals = this.kanban.split('@@');
            this.id = vals[0];
            this.level = vals[1]; 
            
            let param ={
                level:this.level,
                id:this.id
            };
            this.loading = true;
           
            this.energyKanBanData = undefined;
            getEnergyKanBanData(param)
            .then((res) => {
                console.log('SetEnergyKanBanSetting result',res);
                this.err = res.errorCode;
                if(this.level==='client'){
                    if((res.errorCode ==='00' || res.errorCode ==='2219') && res.data){
                        this.energyKanBanData = res.data;
                        this.name=res.data.name;
                        this.address=res.data.address;
                        if(res.errorCode ==='2219'){
                            this.$message.error(res.msg);
                        }
                        this.getWeather();
                    } else{
                        this.msg = res.msg;
                        this.name = "----";
                        this.address = "----";
                        this.energyKanBanData = undefined;
                        this.$message.error(res.msg);
                    }
                }else{
                    if((res.errorCode ==='00' || res.errorCode ==='2219') && res.data.kanBanModualList){
                        this.energyKanBanData = res.data.kanBanModualList;
                        this.name=res.data.name;
                        this.address=res.data.address;
                        this.getWeather();
                        this.getAlarmCount();
                        if(res.errorCode ==='2219'){
                            this.$message.error(res.msg);
                        }
                    } else{
                        this.name = "----";
                        this.address = "----";
                        this.msg = res.msg;
                        this.$message.error(res.msg);
                    }
                }
                
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });

           
        },
        getAlarmCount(){
            getEnergyKanBanAlarmCount(this.id,this.level)
            .then((res) => {
                console.log('GetEnergyKanBanAlarmCount result',res);
                if(res.errorCode ==='00' && res.data){
                    this.alarmCount = res.data;
                } else{
                    this.alarmCount = alarmCount;
                    this.$message.error(res.msg);
                }
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        getWeather(){
            getEnergyKanBanWeather(this.id,this.level)
            .then((res) => {
                console.log('GetEnergyKanBanWeather result',res);
                if(res.errorCode ==='00' && res.data){
                    this.weather = res.data;
                } else{
                    this.weather = weather;
                    this.$message.error(res.msg);
                }
                // this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        getWeatherClz(code){
            return 'qi-'+code;
        },
        getWindScale(scale){
            let lang = localStorage.getItem("lang");
            if(lang=='enus'){
                return this.$t('Kanban.a83') + " " + scale ;
            } else {
                return scale + " " +  this.$t('Kanban.a83');
            }
        },
        getWindDir(dir){
            let lang = localStorage.getItem("lang");
            if(lang=='zhtw' || lang=='zhcn'){
                return dir ;
            } else {
                if(dir=='S'){
                    return this.$t('Kanban.a78');
                } else if(dir=='N'){
                    return this.$t('Kanban.a75');
                } else if(dir=='E'){
                    return this.$t('Kanban.a82');
                } else if(dir=='W'){
                    return this.$t('Kanban.a81');
                } else if(dir=='SE'){
                    return this.$t('Kanban.a79');
                } else if(dir=='SW'){
                    return this.$t('Kanban.a80');
                } else if(dir=='NE'){
                    return this.$t('Kanban.a76');
                } else if(dir=='NW'){
                    return this.$t('Kanban.a77');
                }
            }
        },
    }
}

</script>

<style scoped>

#newSiteKanban{
    width: 100%;
    height: 100%;
    background-color: #f2f3f8;
    /* font-family: "Microsoft YaHei"; */
    position: relative;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    height: 0.52rem;
    padding: 10px 20px;
    border-left: 1px solid rgba(12, 12, 12, 0.1);
}
.header-left{
    display: flex;
    align-items: center;
}
.header-right{
     display: flex;
}
.header-name{
    font-size: 0.16rem;
    font-weight:600;
    margin-right: 20px;
}
.weather{
  display: flex;
  align-items: center;
  font-size: 0.14rem;
}
.address{
  display: flex;
  align-items: center;
  font-size: 0.14rem;
}
.time{
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  padding:0 0.1rem;
}
img{
  width: 0.25rem;
  margin-right: 10px;
}
.icon{
  margin: 0px 10px 0px 20px;
}
.main{
    width: 100%;
    height: calc(100% - 0.5rem);
    padding: 0.1rem;
}
.module{
    width: 100%;
    height: 100%;
}
.kanban{
    height: calc(100% - 1.12rem);
    padding: 0.1rem;
}
.client-floor{
    right: 0.12rem;
    bottom: 0.1rem;
    width: 0.26rem;
    height: 0.26rem;
    border-radius: 0.18rem;
    background-color: rgba(12, 12, 12, 0.1);
    position: absolute;
    cursor: pointer;
}
.client-floor:hover{
    background-color:#7682CE;
}
.floor{
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
}
.floor-left{
    display: flex;
    align-items: center;
    height: 0.6rem;
    background-color: #ffffff;
    padding: 0px  0.2rem;
    border-left: 1px solid rgba(100, 100, 100, 0.1);
}
.floor-right{
    padding: 0px  0.15rem;
    display: flex;
    align-items: center;
    height: 0.6rem;
}
.floor img{
    width: 0.35rem;
    
}
.img-container{
    display: flex;
    align-items: center;
    font-weight:600;
    margin-right: 0.2rem;
}
.interval{
    margin-right: 0.5rem;
}

.card{
    width:48%;
    height: 400px;
    float: left;
    background-color: #ffffff;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
}
pre{
    width:100%;
    height: 100%;
   overflow: auto;
}
.tds{
    padding: 0.05rem;
    /* border-left: 1px solid #f5f5f5; */
}
.icon{
    margin: 0 0 0 10px;
}
</style>