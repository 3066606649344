<!-- 全功能看板 -->
<template>
    <div id="globalFunction" ref="kpichart">
         <a-row :gutter="24" style="height:100%;">
            <!-- 用电概况 -->
            <a-col v-if="enekind1.display" :span="cardSpan"  >
                <div class="frame electricity">
                    <div class="card-title">
                        <img class="card-title-img" src="../../../../public/images/kanban/electricity.png" />
                        <!-- 用电概况 -->
                        <span>{{enekind1.title}}</span>
                    </div>
                    <div class="card-main" v-if="cardSpan<24"> 
                        <div class="card-font-info">
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a1')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{enekind1.yearValue}}{{enekind1.unit}}</span>
                                    </a-col>
                                </a-row>

                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ enekind1.rateLabel}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span v-if="enekind1.curtYearAimRate<0" style="color: #FF0000">
                                            {{Number(enekind1.curtYearAimValue - enekind1.yearValue).toFixed(2)}}{{enekind1.unit}}
                                            <!-- {{enekind1.curtYearAimRate}}% -->
                                            <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                        </span>
                                        <span v-else-if="enekind1.curtYearAimRate===0" style="color: #e8e8e8">
                                            {{Number(enekind1.curtYearAimValue - enekind1.yearValue).toFixed(2)}}{{enekind1.unit}}
                                            <!-- {{enekind1.curtYearAimRate}}% -->
                                            <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                        </span>
                                        <span v-else style="color: #20b541">
                                            {{Number(enekind1.curtYearAimValue - enekind1.yearValue).toFixed(2)}}{{enekind1.unit}}
                                            <!-- {{enekind1.curtYearAimRate}}%-->
                                            <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                        </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a2')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{enekind1.monthValue}}{{enekind1.unit}}</span>
                                    </a-col>
                                </a-row>
                                
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ enekind1.rateLabel}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span v-if="enekind1.curtMonthAimRate<0" style="color: #FF0000">
                                            {{Number(enekind1.curtMonthAimValue - enekind1.monthValue).toFixed(2)}}{{enekind1.unit}}
                                            <!-- {{enekind1.curtMonthAimRate}}% -->
                                            <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                        </span>
                                        <span v-else-if="enekind1.curtMonthAimRate===0" style="color: #e8e8e8">
                                            {{Number(enekind1.curtMonthAimValue - enekind1.monthValue).toFixed(2)}}{{enekind1.unit}}
                                            <!-- {{enekind1.curtMonthAimRate}}% -->
                                            <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                        </span>
                                        <span v-else style="color: #20b541">
                                            {{Number(enekind1.curtMonthAimValue - enekind1.monthValue).toFixed(2)}}{{enekind1.unit}}
                                            <!-- {{enekind1.curtMonthAimRate}}% -->
                                            <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                        </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a3')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{enekind1.dayValue}}{{enekind1.unit}}</span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        <!-- 实时功率 -->
                                        {{$t('Kanban.a5')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom-text" v-if="enekind1.powerValue == 0">
                                        <!-- 未获取 -->
                                        {{$t('Kanban.a86')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom" v-else>
                                        <span>{{enekind1.powerValue + ' ' + enekind1.powerUnit}}</span>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                        <div class="chart" >
                            <div class="chart-yearKPI">
                                <div :id="yearAimId" :style="{ width: '100%', height: '100%' }"></div>
                            </div>
                            <div class="chart-monthKPI">
                                <div :id="monthAimId" :style="{ width: '100%', height: '100%' }"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card-main" v-if="cardSpan==24"> 
                        <a-row :gutter="24" style="width:100%;height:100%;">
                            <a-col :span="6" style="height:100%;">
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ $t('Kanban.a1')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span>{{enekind1.yearValue}}{{enekind1.unit}}</span>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ enekind1.rateLabel}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span v-if="enekind1.curtYearAimRate<0" style="color: #FF0000">
                                                {{Number(enekind1.curtYearAimValue - enekind1.yearValue).toFixed(2)}}{{enekind1.unit}}
                                                <!-- {{enekind1.curtYearAimRate}}% -->
                                                <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                            </span>
                                            <span v-else-if="enekind1.curtYearAimRate===0" style="color: #e8e8e8">
                                                {{Number(enekind1.curtYearAimValue - enekind1.yearValue).toFixed(2)}}{{enekind1.unit}}
                                                <!-- {{enekind1.curtYearAimRate}}% -->
                                                <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                            </span>
                                            <span v-else style="color: #20b541">
                                                {{Number(enekind1.curtYearAimValue - enekind1.yearValue).toFixed(2)}}{{enekind1.unit}}
                                                <!-- {{enekind1.curtYearAimRate}}% -->
                                                <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                            </span>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ $t('Kanban.a2')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span>{{enekind1.monthValue}}{{enekind1.unit}}</span>
                                        </a-col>
                                    </a-row>
                                    
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ enekind1.rateLabel}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span v-if="enekind1.curtMonthAimRate<0" style="color: #FF0000">
                                                {{Number(enekind1.curtMonthAimValue - enekind1.monthValue).toFixed(2)}}{{enekind1.unit}}
                                                <!-- {{enekind1.curtMonthAimRate}}% -->
                                                <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                            </span>
                                            <span v-else-if="enekind1.curtMonthAimRate===0" style="color: #e8e8e8">
                                                {{Number(enekind1.curtMonthAimValue - enekind1.monthValue).toFixed(2)}}{{enekind1.unit}}
                                                <!-- {{enekind1.curtMonthAimRate}}% -->
                                                <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                            </span>
                                            <span v-else style="color: #20b541">
                                                {{Number(enekind1.curtMonthAimValue - enekind1.monthValue).toFixed(2)}}{{enekind1.unit}}
                                                <!-- {{enekind1.curtMonthAimRate}}% -->
                                                <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                            </span>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ $t('Kanban.a3')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span>{{enekind1.dayValue}}{{enekind1.unit}}</span>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            <!-- 实时功率 -->
                                            {{$t('Kanban.a5')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom-text" v-if="enekind1.powerValue == 0">
                                            <!-- 未获取 -->
                                            {{$t('Kanban.a86')}}
                                            
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom" v-else>
                                            <span>{{enekind1.powerValue + ' ' + enekind1.powerUnit}}</span>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-col>
                            <a-col :span="10" style="height:100%;border-left: 1px solid #e8e8e8;">
                                <div :id="waterChartId" :style="{ width: '95%', height: '100%' }"></div>
                            </a-col>
                            <a-col :span="8" style="height:100%;display:flex;border-left: 1px solid #e8e8e8;">
                                <div class="chart-yearKPI">
                                    <div :id="yearAimId" :style="{ width: '100%', height: '100%' }"></div>
                                </div>
                                <div class="chart-monthKPI">
                                    <div :id="monthAimId" :style="{ width: '100%', height: '100%' }"></div>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </a-col>
            <!-- 用水概况 -->
            <a-col v-if="water.display" :span="cardSpan" >
                <div class="frame water">
                    <div class="card-title">
                        <img class="card-title-img" src="../../../../public/images/kanban/water.png" />
                        <!-- 用水概况 -->
                        <span>{{water.title}}</span>
                    </div>
                    <div class="card-main" v-if="cardSpan<24"> 
                        <div class="card-font-info-3">
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a1')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{water.yearValue}}{{water.unit}}</span>
                                    </a-col>
                                </a-row>

                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ water.rateLabel}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span v-if="water.curtYearAimRate<0" style="color: #FF0000">
                                            {{Number(water.curtYearAimValue - water.yearValue).toFixed(2)}}{{water.unit}}
                                            <!-- {{water.curtYearAimRate}}% -->
                                            <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                        </span>
                                        <span v-else-if="water.curtYearAimRate===0" style="color: #e8e8e8">
                                            {{Number(water.curtYearAimValue - water.yearValue).toFixed(2)}}{{water.unit}}
                                            <!-- {{water.curtYearAimRate}}% -->
                                            <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                        </span>
                                        <span v-else style="color: #20b541">
                                            {{Number(water.curtYearAimValue - water.yearValue).toFixed(2)}}{{water.unit}}
                                            <!-- {{water.curtYearAimRate}}% -->
                                            <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                        </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a2')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{water.monthValue}}{{water.unit}}</span>
                                    </a-col>
                                </a-row>
                                
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ water.rateLabel}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span v-if="water.curtMonthAimRate<0" style="color: #FF0000">
                                            {{Number(water.curtMonthAimValue - water.monthValue).toFixed(2)}}{{water.unit}}
                                            <!-- {{water.curtMonthAimRate}}% -->
                                            <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                        </span>
                                        <span v-else-if="water.curtMonthAimRate===0" style="color: #e8e8e8">
                                            {{Number(water.curtMonthAimValue - water.monthValue).toFixed(2)}}{{water.unit}}
                                            <!-- {{water.curtMonthAimRate}}% -->
                                            <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                        </span>
                                        <span v-else style="color: #20b541">
                                            {{Number(water.curtMonthAimValue - water.monthValue).toFixed(2)}}{{water.unit}}
                                            <!-- {{water.curtMonthAimRate}}% -->
                                            <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                        </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a3')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{water.dayValue}}{{water.unit}}</span>
                                    </a-col>
                                </a-row>

                                
                            </div>
                        </div>
                        <div class="chart">
                            <div :id="waterChartId" :style="{ width: '95%', height: '100%' }"></div>
                        </div>
                    </div>
                    <div class="card-main" v-else-if="cardSpan==24"> 
                        <a-row :gutter="24" style="width:100%;height:100%;">
                            <a-col :span="6" style="height:100%;">
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ $t('Kanban.a1')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span>{{water.yearValue}}{{water.unit}}</span>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ water.rateLabel}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span v-if="water.curtYearAimRate<0" style="color: #FF0000">
                                                {{Number(water.curtYearAimValue - water.yearValue).toFixed(2)}}{{water.unit}}
                                                <!-- {{water.curtYearAimRate}}% -->
                                                <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                            </span>
                                            <span v-else-if="water.curtYearAimRate===0" style="color: #e8e8e8">
                                                {{Number(water.curtYearAimValue - water.yearValue).toFixed(2)}}{{water.unit}}
                                                <!-- {{water.curtYearAimRate}}% -->
                                                <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                            </span>
                                            <span v-else style="color: #20b541">
                                                {{Number(water.curtYearAimValue - water.yearValue).toFixed(2)}}{{water.unit}}
                                                <!-- {{water.curtYearAimRate}}% -->
                                                <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                            </span>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ $t('Kanban.a2')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span>{{water.monthValue}}{{water.unit}}</span>
                                        </a-col>
                                    </a-row>
                                    
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ water.rateLabel}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                           
                                            
                                            <span v-if="water.curtMonthAimRate<0" style="color: #FF0000">
                                                {{Number(water.curtMonthAimValue - water.monthValue).toFixed(2)}}{{water.unit}}
                                                <!-- {{water.curtMonthAimRate}}% -->
                                                <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                            </span>
                                            <span v-else-if="water.curtMonthAimRate===0" style="color: #e8e8e8">                                                
                                                {{Number(water.curtMonthAimValue - water.monthValue).toFixed(2)}}{{water.unit}}
                                                <!-- {{water.curtMonthAimRate}}% -->
                                                <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                            </span>
                                            <span v-else style="color: #20b541">
                                                {{Number(water.curtMonthAimValue - water.monthValue).toFixed(2)}}{{water.unit}}
                                                <!-- {{water.curtMonthAimRate}}% -->
                                                <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                            </span>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ $t('Kanban.a3')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span>{{water.dayValue}}{{water.unit}}</span>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-col>
                            <a-col :span="10" style="height:100%;border-left: 1px solid #e8e8e8;">
                                <div :id="waterChartId" :style="{ width: '95%', height: '100%' }"></div>
                            </a-col>
                            <a-col :span="8" style="height:100%;display:flex;border-left: 1px solid #e8e8e8;">
                                <div class="chart-yearKPI">
                                    <div :id="yearAimId" :style="{ width: '100%', height: '100%' }"></div>
                                </div>
                                <div class="chart-monthKPI">
                                    <div :id="monthAimId" :style="{ width: '100%', height: '100%' }"></div>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
                
            </a-col>

            <!-- 用气概况 -->
           <a-col v-if="gas.display" :span="cardSpan" >
                <div class="frame gas">
                    <div class="card-title">
                        <img class="card-title-img" src="../../../../public/images/kanban/gas.png" />
                        <!-- 用气概况  -->
                        <span>{{gas.title}}</span>
                    </div>
                    <div class="card-main" v-if="cardSpan<24"> 
                        
                        <div class="card-font-info-3">
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a1')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{gas.yearValue}}{{gas.unit}}</span>
                                    </a-col>
                                </a-row>

                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ gas.rateLabel}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">

                                        <span v-if="gas.curtYearAimRate<0" style="color: #FF0000">
                                            {{Number(gas.curtYearAimValue - gas.yearValue).toFixed(2)}}{{gas.unit}}
                                            <!-- {{gas.curtYearAimRate}}% -->
                                            <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                        </span>
                                        <span v-else-if="gas.curtYearAimRate===0" style="color: #e8e8e8">
                                            {{Number(gas.curtYearAimValue - gas.yearValue).toFixed(2)}}{{gas.unit}}
                                            <!-- {{gas.curtYearAimRate}}% -->
                                            <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                        </span>
                                        <span v-else style="color: #20b541">
                                            {{Number(gas.curtYearAimValue - gas.yearValue).toFixed(2)}}{{gas.unit}}
                                            <!-- {{gas.curtYearAimRate}}% -->
                                            <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                        </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a2')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{gas.monthValue}}{{gas.unit}}</span>
                                    </a-col>
                                </a-row>
                                
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ gas.rateLabel}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                                                              
                                        <span v-if="gas.curtMonthAimRate<0" style="color: #FF0000">
                                            {{Number(gas.curtMonthAimValue - gas.monthValue).toFixed(2)}}{{gas.unit}}
                                            <!-- {{gas.curtMonthAimRate}}% -->
                                            <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                        </span>
                                        <span v-else-if="gas.curtMonthAimRate===0" style="color: #e8e8e8">
                                            {{Number(gas.curtMonthAimValue - gas.monthValue).toFixed(2)}}{{gas.unit}}
                                            <!-- {{gas.curtMonthAimRate}}% -->
                                            <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                        </span>
                                        <span v-else style="color: #20b541">
                                            {{Number(gas.curtMonthAimValue - gas.monthValue).toFixed(2)}}{{gas.unit}}
                                            <!-- {{gas.curtMonthAimRate}}% -->
                                            <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                        </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a3')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{gas.dayValue}}{{gas.unit}}</span>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                        <div class="chart">
                            <div :id="gasChartId" :style="{ width: '95%', height: '100%' }"></div>
                        </div>
                    </div>
                    <div class="card-main" v-else-if="cardSpan==24"> 
                        <a-row :gutter="24" style="width:100%;height:100%;">
                            <a-col :span="6" style="height:100%;">
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ $t('Kanban.a1')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span>{{gas.yearValue}}{{gas.unit}}</span>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ gas.rateLabel}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            
                                            <span v-if="gas.curtYearAimRate<0" style="color: #FF0000">
                                                {{Number(gas.curtYearAimValue - gas.yearValue).toFixed(2)}}{{gas.unit}}
                                                <!-- {{gas.curtYearAimRate}}% -->
                                                <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                            </span>
                                            <span v-else-if="gas.curtYearAimRate===0" style="color: #e8e8e8">
                                                {{Number(gas.curtYearAimValue - gas.yearValue).toFixed(2)}}{{gas.unit}}
                                                <!-- {{gas.curtYearAimRate}}% -->
                                                <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                            </span>
                                            <span v-else style="color: #20b541">
                                                {{Number(gas.curtYearAimValue - gas.yearValue).toFixed(2)}}{{gas.unit}}
                                                <!-- {{gas.curtYearAimRate}}% -->
                                                <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                            </span>

                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ $t('Kanban.a2')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span>{{gas.monthValue}}{{gas.unit}}</span>
                                        </a-col>
                                    </a-row>
                                    
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ gas.rateLabel}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            
                                            <span v-if="gas.curtMonthAimRate<0" style="color: #FF0000">
                                                {{Number(gas.curtMonthAimValue - gas.monthValue).toFixed(2)}}{{gas.unit}}
                                                <!-- {{gas.curtMonthAimRate}}% -->
                                                <!-- <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/> -->
                                            </span>
                                            <span v-else-if="gas.curtMonthAimRate===0" style="color: #e8e8e8">
                                                {{Number(gas.curtMonthAimValue - gas.monthValue).toFixed(2)}}{{gas.unit}}
                                                <!-- {{gas.curtMonthAimRate}}% -->
                                                <!-- <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/> -->
                                            </span>
                                            <span v-else style="color: #20b541">
                                                {{Number(gas.curtMonthAimValue - gas.monthValue).toFixed(2)}}{{gas.unit}}
                                                <!-- {{gas.curtMonthAimRate}}% -->
                                                <!-- <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/> -->
                                            </span>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="card-row">
                                    <a-row :gutter="16">
                                        <a-col :span="8" class="card-row-top">
                                            {{ $t('Kanban.a3')}}
                                        </a-col>
                                        <a-col :span="16" class="card-row-bottom">
                                            <span>{{gas.dayValue}}{{gas.unit}}</span>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-col>
                            <a-col :span="10" style="height:100%;border-left: 1px solid #e8e8e8;">
                                <div :id="gasChartId" :style="{ width: '95%', height: '100%' }"></div>
                            </a-col>
                            <a-col :span="8" style="height:100%;display:flex;border-left: 1px solid #e8e8e8;">
                                <div class="chart-yearKPI">
                                    <div :id="yearAimId" :style="{ width: '100%', height: '100%' }"></div>
                                </div>
                                <div class="chart-monthKPI">
                                    <div :id="monthAimId" :style="{ width: '100%', height: '100%' }"></div>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
                
            </a-col>

            <!-- 节能减排 -->
            <a-col v-if="carbon.display" :span="cardSpan" >
                <div class="frame carbon">
                    <div class="card-title">
                        <img class="card-title-img" src="../../../../public/images/kanban/recycle.png" />
                        <!-- <span>节能减排</span> -->
                        <span>{{carbon.title}}</span>
                    </div>
                    <!-- 无清洁能源 -->
                    <div class="card-main"  v-if="!carbon.hasCleanEnergy"> 
                        <div class="card-font-info" >
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a1')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{carbon.yearValue}}{{carbon.unit}}</span>
                                    </a-col>
                                </a-row>

                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ carbon.rateLabel}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span v-if="carbon.yearRate<0" style="color: #20b541">
                                            {{carbon.yearRate*-1}}%
                                            <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                        </span>
                                        <span v-else-if="carbon.yearRate===0" style="color: #e8e8e8">
                                            {{carbon.yearRate}}%
                                            <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                        </span>
                                        <span v-else style="color: #f36503">
                                            {{carbon.yearRate}}%
                                            <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                        </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a2')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{carbon.monthValue}}{{carbon.unit}}</span>
                                    </a-col>
                                </a-row>
                                
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ carbon.rateLabel}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span v-if="carbon.monthRate<0" style="color: #20b541">
                                            {{carbon.monthRate*-1}}%
                                            <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                        </span>
                                        <span v-else-if="carbon.monthRate===0" style="color: #e8e8e8">
                                            {{carbon.monthRate}}%
                                            <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                        </span>
                                        <span v-else style="color: #f36503">
                                            {{carbon.monthRate}}%
                                            <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                        </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="card-row">
                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ $t('Kanban.a3')}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span>{{carbon.dayValue}}{{carbon.unit}}</span>
                                    </a-col>
                                </a-row>

                                <a-row :gutter="16">
                                    <a-col :span="8" class="card-row-top">
                                        {{ carbon.rateLabel}}
                                    </a-col>
                                    <a-col :span="16" class="card-row-bottom">
                                        <span v-if="carbon.dayRate<0" style="color: #20b541">
                                            {{carbon.dayRate*-1}}%
                                            <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                        </span>
                                        <span v-else-if="carbon.dayRate===0" style="color: #e8e8e8">
                                            {{carbon.dayRate}}%
                                            <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                        </span>
                                        <span v-else style="color: #f36503">
                                            {{carbon.dayRate}}%
                                            <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                        </span>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                        <div class="chart">
                            <div :id="carbonChartId" :style="{ width: '95%', height: '100%' }"></div>
                        </div>
                    </div>
                    <!-- 清洁能源 -->
                     <div class="card-main2"  v-else>
                         <a-row class="card-main2-item-title" style="padding:0.05rem 0;">
                             <a-col :span="11">
                                 <!-- 碳排放 -->
                                 {{$t('Kanban.a8')}}
                             </a-col>
                             <a-col :span="12" style="padding-left: 0.1rem;margin-left:0.05rem;">
                                 <!-- 清洁能源发电 -->
                                 {{$t('Kanban.a9')}}
                             </a-col>
                         </a-row>
                         <a-row class="card-main2-item-content">
                             <a-col :span="11">
                                 <div class="card-font-info-4-left" >
                                    <div class="card-row">
                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ $t('Kanban.a1')}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span>{{carbon.yearValue}}{{carbon.unit}}</span>
                                            </a-col>
                                        </a-row>

                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ carbon.rateLabel}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span v-if="carbon.yearRate<0" style="color: #20b541">
                                                    {{carbon.yearRate*-1}}%
                                                    <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                                </span>
                                                <span v-else-if="carbon.yearRate===0" style="color: #e8e8e8">
                                                    {{carbon.yearRate}}%
                                                    <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                                </span>
                                                <span v-else style="color: #f36503">
                                                    {{carbon.yearRate}}%
                                                    <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                                </span>
                                            </a-col>
                                        </a-row>
                                    </div>
                                    <div class="card-row">
                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ $t('Kanban.a2')}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span>{{carbon.monthValue}}{{carbon.unit}}</span>
                                            </a-col>
                                        </a-row>
                                        
                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ carbon.rateLabel}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span v-if="carbon.monthRate<0" style="color: #20b541">
                                                    {{carbon.monthRate*-1}}%
                                                    <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                                </span>
                                                <span v-else-if="carbon.monthRate===0" style="color: #e8e8e8">
                                                    {{carbon.monthRate}}%
                                                    <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                                </span>
                                                <span v-else style="color: #f36503">
                                                    {{carbon.monthRate}}%
                                                    <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                                </span>
                                            </a-col>
                                        </a-row>
                                    </div>
                                    <div class="card-row">
                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ $t('Kanban.a3')}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span>{{carbon.dayValue}}{{carbon.unit}}</span>
                                            </a-col>
                                        </a-row>

                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ carbon.rateLabel}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span v-if="carbon.dayRate<0" style="color: #20b541;">
                                                    {{carbon.dayRate*-1}}%
                                                    <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                                </span>
                                                <span v-else-if="carbon.dayRate===0" style="color: #e8e8e8">
                                                    {{carbon.dayRate}}%
                                                    <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                                </span>
                                                <span v-else style="color: #f36503;">
                                                    {{carbon.dayRate}}%
                                                    <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                                </span>
                                            </a-col>
                                        </a-row>
                                    </div>
                                </div>
                             </a-col>
                             <a-col :span="12" style="margin-left: 0.1rem;border-left:1px solid #e8e8e8;">
                                 <div class="card-font-info-4" >
                                    <div class="card-row">
                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ $t('Kanban.a1')}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span>{{carbon.yearCleanValue}}KWh</span>
                                                <!-- {{carbon.unit}} -->
                                            </a-col>
                                        </a-row>

                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ carbon.rateLabel}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span v-if="carbon.yearCleanRate<0" style="color: #20b541">
                                                    {{carbon.yearCleanRate*-1}}%
                                                    <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                                </span>
                                                <span v-else-if="carbon.yearCleanRate===0" style="color: #e8e8e8">
                                                    {{carbon.yearCleanRate}}%
                                                    <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                                </span>
                                                <span v-else style="color: #f36503">
                                                    {{carbon.yearCleanRate}}%
                                                    <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                                </span>
                                            </a-col>
                                        </a-row>
                                    </div>
                                    <div class="card-row">
                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ $t('Kanban.a2')}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span>{{carbon.monthCleanValue}}KWh</span>
                                            </a-col>
                                        </a-row>
                                        
                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ carbon.rateLabel}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span v-if="carbon.monthCleanRate<0" style="color: #20b541">
                                                    {{carbon.monthCleanRate*-1}}%
                                                    <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                                </span>
                                                <span v-else-if="carbon.monthCleanRate===0" style="color: #e8e8e8">
                                                    {{carbon.monthCleanRate}}%
                                                    <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                                </span>
                                                <span v-else style="color: #f36503">
                                                    {{carbon.monthCleanRate}}%
                                                    <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                                </span>
                                            </a-col>
                                        </a-row>
                                    </div>
                                    <div class="card-row">
                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ $t('Kanban.a3')}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span>{{carbon.dayCleanValue}}KWh</span>
                                            </a-col>
                                        </a-row>

                                        <a-row :gutter="16">
                                            <a-col :span="8" class="card-row-top-4">
                                                {{ carbon.rateLabel}}
                                            </a-col>
                                            <a-col :span="16" class="card-row-bottom">
                                                <span v-if="carbon.dayCleanRate<0" style="color: #20b541">
                                                    {{carbon.dayCleanRate*-1}}%
                                                    <a-icon type="fall" :style="{ fontSize: '0.18rem',color: '#20b541' }"/>
                                                </span>
                                                <span v-else-if="carbon.dayCleanRate===0" style="color: #e8e8e8">
                                                    {{carbon.dayCleanRate}}%
                                                    <a-icon type="minus-outlined" :style="{ fontSize: '0.18rem',color: '#e8e8e8' }"/>
                                                </span>
                                                <span v-else style="color: #f36503">
                                                    {{carbon.dayCleanRate}}%
                                                    <a-icon type="rise" :style="{ fontSize: '0.18rem',color: '#f36503' }"/>
                                                </span>
                                            </a-col>
                                        </a-row>
                                    </div>
                                </div>
                             </a-col>
                         </a-row>
                     </div> 
                </div>
                
            </a-col>

            <!-- 电能流向桑基图 -->
            <a-col :span="12" class="echarts-container" v-if="flow.display">
                <div class="frame sankey-container">
                    <div class="card-title">
                        <span>{{this.flow.title }}</span>
                    </div>
                    <div class="container-content">

                        <div :id="flowChartId" :style="{ width: '100%', height: '100%' }"></div>
                    </div>
                </div>
            </a-col>

            <a-col :span="12" class="echarts-container" >
                <a-row style="height:100%;">
                     <!-- 峰谷分析柱状图 -->
                    <a-col :span="24"  class="compartment" v-if="section.display">
                        <div class="frame compartment-container">
                            <div class="card-title">
                                <!-- <span>峰谷分析</span> -->
                                <span>{{section.title}}</span>
                            </div>
                            <div class="container-content">
                                <div :id="peakValleyId" :style="{ width: '100%', height: '100%' }"></div>
                            </div>
                        </div>
                    </a-col>
                    <!-- 用电明细对比折线图 -->
                    <a-col :span="24" class="compartment" v-if="detail.display">
                        <div class="frame compartment-container">
                            <div class="card-title">
                                <span>{{detail.title }}</span>
                            </div>
                            <div class="container-content">
                                <div :id="detailChartId" :style="{ width: '100%', height: '100%' }"></div>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { EleResize } from '../../../utils/esresize'
import moment from "moment";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
    props: {
        list: {
            type: Array,
            default:[],
        },
    },
    data(){
        let top = '5%';
        return {
            yearKPIData: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}:{c}%'
                },
                title: {
                    text: this.$t('Kanban.a6'),//'年度KPI',
                    left: 'left',
                    textStyle: {
                        fontSize: '0.14rem',
                        color:'#000000',
                        fontFamily: 'Microsoft YaHei'
                    }
                },
                series: [
    
                    {
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        pointer: {
                            show: false
                        },
                        radius: "90%",
                        progress: {
                            show: true,
                            overlap: false,
                            roundCap: false,
                            clip: true,
                            // itemStyle: {
                            //     borderWidth: 0,
                            //     borderColor: '#464646'
                            // }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 25,
                            }
                        },
                        splitLine: {
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            distance: 50
                        },
                        data: [
                            {
                                value: 0,
                                name: 'KPI',
                                title: {
                                    itemStyle: {
                                        color: "#7580d0",
                                    },
                                    offsetCenter: ['0%', '-45%']
                                },
                                detail: {
                                    valueAnimation: true,
                                    offsetCenter: ['0%', '-20%']
                                },
                                itemStyle: {
                                    color: "#7580d0",
                                },
                            },
                            {
                                value: Number(28/365*100).toFixed(2),
                                name: this.$t('Kanban.a87'),//'时间进度',
                                title: {
                                    itemStyle: {
                                        color: "#99d080",
                                    },
                                    offsetCenter: ['0%', '10%']
                                },
                                detail: {
                                valueAnimation: true,
                                    offsetCenter: ['0%', '35%']
                                },
                                itemStyle: {
                                    color: "#99d080",
                                },
                            }
                        ],
                        title: {
                            fontSize: 14,
                        },
                        detail: {
                            width: 40,
                            height: 10,
                            fontSize: 12,
                            color: 'inherit',
                            borderColor: 'inherit',
                            borderRadius: 10,
                            borderWidth: 1,
                            formatter: '{value}%'
                        }
                    }
                    
                ],
            },
            monthKPIData: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}:{c}%',
                },
                title: {
                    text: this.$t('Kanban.a7'),//'月度KPI',
                    left: 'left',
                    textStyle: {
                        fontSize: '0.14rem',
                        color:'#000000',
                        fontFamily: 'Microsoft YaHei'
                    }
                },
                series: [
                    {
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        pointer: {
                            show: false
                        },
                        radius: "90%",
                        progress: {
                            show: true,
                            overlap: false,
                            roundCap: false,
                            clip: true,
                            itemStyle: {
                                borderWidth: 0,
                                borderColor: '#464646'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 25
                            }
                        },
                        splitLine: {
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            distance: 50
                        },
                        data: [
                            {
                                value: 0,
                                name: 'KPI',
                                title: {
                                    itemStyle: {
                                        color: "#7580d0",
                                    },
                                    offsetCenter: ['0%', '-45%']
                                },
                                detail: {
                                    valueAnimation: true,
                                    offsetCenter: ['0%', '-20%']
                                },
                                itemStyle: {
                                    color: "#7580d0",
                                },
                            },
                            {
                                value: Number(28/30*100).toFixed(2),
                                name: this.$t('Kanban.a87'),//'时间进度',
                                title: {
                                    itemStyle: {
                                        color: "#99d080",
                                    },
                                    offsetCenter: ['0%', '10%']
                                },
                                detail: {
                                valueAnimation: true,
                                    offsetCenter: ['0%', '35%']
                                },
                                itemStyle: {
                                    color: "#99d080",
                                },
                            }
                        ],
                        title: {
                            fontSize: 14
                        },
                        detail: {
                            width: 40,
                            height: 10,
                            fontSize: 12,
                            color: 'inherit',
                            borderColor: 'inherit',
                            borderRadius: 10,
                            borderWidth: 1,
                            formatter: '{value}%'
                        }
                    }
                ],
            },
            carbonEmissionData:{
              title: {
                  text: this.$t('Kanban.a33'),//'每日碳排放',
                  left: "center",
                  bottom: 10,
                  textStyle: {
                      fontSize: '0.12rem',
                      color:'#000000',
                      fontWeight: '300',
                      fontFamily: 'Microsoft YaHei'
                  }
              },
              tooltip: {
                    trigger: 'axis'
              },
              grid: {
                top:'5%',
                left: '3%',
                right: '5%',
                bottom: '20%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                data: ['1', '2', '3', '4', '5', '6', '7','8', '9', '10', '11', '12', '13', '14']
              },
              yAxis: { 
                name: 'kg',
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                }
              },
              series: [
                {
                  data: [150, 230, 224, 218, 135, 147, 260],
                  type: 'line',
                  color:'#e8e8e8',
                }
              ]
            },
            waterData: {
                color:['#7580d0'],
                title: {
                    text: this.$t('Kanban.a34'),//'每周用水量',
                    left: 'center',
                    bottom: 10,
                    textStyle: {
                        fontSize: '0.12rem',
                        color:'#000000',
                        fontWeight: '300',
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top:'5%',
                    left: '3%',
                    right: '6%',
                    bottom: '20%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['1', '2', '3', '4', '5', '6', '7']
                },
                yAxis: [{
                    name: 'kg',
                    type: 'value'
                }],
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar'
                    }
                ]
            },
            elcData: {
                color:['#7580d0'],
                title: {
                    text: this.$t('Kanban.a34'),
                    left: 'center',
                    bottom: 10,
                    textStyle: {
                        fontSize: '0.12rem',
                        color:'#000000',
                        fontWeight: '300',
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top:'5%',
                    left: '3%',
                    right: '6%',
                    bottom: '20%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['1', '2', '3', '4', '5', '6', '7']
                },
                yAxis: [{
                    name: 'kg',
                    type: 'value'
                }],
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar'
                    }
                ]
            },
            gasData: {
                color:['#7580d0'],
                title: {
                    text: this.$t('Kanban.a34'),
                    left: 'center',
                    bottom: 10,
                    textStyle: {
                        fontSize: '0.12rem',
                        color:'#000000',
                        fontWeight: '300',
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top:'5%',
                    left: '3%',
                    right: '6%',
                    bottom: '20%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['1', '2', '3', '4', '5', '6', '7']
                },
                yAxis: [{
                    name: 'kg',
                    type: 'value'
                }],
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar'
                    }
                ]
            },
            sankeyData: {
                // title: {
                //     text: '集团月度KPI',
                //     left: "5%",
                //     top: '9%',
                //     textStyle: {
                //         fontSize: '0.15rem',
                //         color:'#000000',
                //         fontWeight: '300',
                //         fontFamily: 'Microsoft YaHei'
                //     }
                // },
                grid:{
                    top:"2%",
                    left: '1%',
                    right: '20%',
                    bottom: '4%'
                },
                color: colors,
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove'
                },
                series: {
                    type: 'sankey',
                    layout: 'none',
                    emphasis: {
                        focus: 'adjacency'
                    },
                    data: [
                        {name: 'a'},
                        {name: 'b'},
                        {name: 'a1'},
                        {name: 'a2'},
                        {name: 'b1'},
                        {name: 'c'}
                    ],
                    links: [
                        {source: 'a',target: 'a1',value: 5},
                        {source: 'a',target: 'a2',value: 3},
                        {source: 'b',target: 'b1',value: 8},
                        {source: 'a',target: 'b1',value: 3},
                        {source: 'b1',target: 'a1',value: 1},
                        {source: 'b1',target: 'c',value: 2}
                    ],
                    label: {
                        fontSize: '0.1rem',
                        color:'#666666',
                        fontWeight: 'normal',
                        fontFamily: 'Microsoft YaHei',
                        rotate:5,
                        padding:[20,0]
                    },
                }
            },
            peakValleyData: {
                // title: {
                //     text: '峰谷分析',
                //     left: "2%",
                //     top: '9%',
                //     textStyle: {
                //         fontSize: '0.15rem',
                //         color:'#000000',
                //         fontWeight: '300',
                //         fontFamily: 'Microsoft YaHei'
                //     }
                // },
                color: colors,
                tooltip: {
                    
                },
                legend: {
                    top:'middle',
                    right:'10%',
                    orient:'vertical',
                    left:'right',
                    data: ['Forest', 'Steppe', 'Desert', 'Wetland']
                },
                grid:{
                    top:top,
                    left: '3%',
                    right: '15%',
                    bottom: '4%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['01', '02', '03', '04', '05', '06', '07','08','09','10','11', '12', '13', '14', '15', '16', '17','18','19','20']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name:'Forest',
                        stack:'aa',
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        itemStyle:{
                            color:'#7580d0'
                        } 
                    },
                    {
                        name:'Steppe',
                        stack:'aa',
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        itemStyle:{
                            color:'#93cd76'
                        } 
                    },
                    {
                        name:'Desert',
                        stack:'aa',
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        itemStyle:{
                            color:'#fbde9a'
                        } 
                    },
                    {
                        name:'Wetland',
                        stack:'aa',
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        itemStyle:{
                            color:'#f06e70'
                        } 
                    }
                ]
            },
            eneYieldData:{
                
                color: colors,
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    top:'middle',
                    right:'10%',
                    orient:'vertical',
                    left:'right',
                    data: ['2020','2021','2022'],
                    type:"scroll",
                },
                grid: {
                    top:  top,
                    left: '3%',
                    right: '15%',
                    bottom: '4%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                    name: 'Email',
                    type: 'line',
                    stack: 'Total',
                    data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                    name: 'Union Ads',
                    type: 'line',
                    stack: 'Total',
                    data: [220, 182, 191, 234, 290, 330, 310]
                    },
                    {
                    name: 'Video Ads',
                    type: 'line',
                    stack: 'Total',
                    data: [150, 232, 201, 154, 190, 330, 410]
                    },
                    {
                    name: 'Direct',
                    type: 'line',
                    stack: 'Total',
                    data: [320, 332, 301, 334, 390, 330, 320]
                    },
                    {
                    name: 'Search Engine',
                    type: 'line',
                    stack: 'Total',
                    data: [820, 932, 901, 934, 1290, 1330, 1320]
                    }
                ]
            },
            enekind1:{
                id: 0,
                display: false,
                kind: "1",
                modual: 1,

                dayValue: 0,
                dayRate: 0,

                monthValue: 0,
                monthRate: 0,
                monthAimRate: 0,
                monthAimValue: 0,

                
                yearValue: 0, 
                yearRate: 0,
                yearAimRate: 0,
                yearAimValue: 0,

                powerUnit: "",
                powerValue: 0,

                rateLabel: "",
                rateType: "1",

                title: "",
                unit: "KWh",    
                curtMonthAimRate: 0,
                curtMonthAimValue: 0,
                curtYearAimRate: 0,
                curtYearAimValue: 0   ,          
            },
            water:{
                id: 0,
                title: "",
                kind: "3",
                modual: 1,
                display: false,
                hasCleanEnergy: false,
                rateLabel: "",
                rateType: "",
                unit: "kg",
                dayRate: 0,
                dayValue: 0,
                dayLastValue: 0,
                monthRate: 0,
                monthValue: 0,
                monthLastValue: 0,
                yearRate: 0,
                yearValue: 0,
                yearLastValue: 0,
                weekValues: [],    
                curtMonthAimRate: 0,
                curtMonthAimValue: 0,
                curtYearAimRate: 0,
                curtYearAimValue: 0   ,   
            },
            gas:{
                id: 0,
                title: "",
                kind: "2",
                modual: 1,
                display: false,
                hasCleanEnergy: false,
                rateLabel: "",
                rateType: "",
                unit: "m³",
                dayRate: 0,
                dayValue: 0,
                dayLastValue: 0,
                monthRate: 0,
                monthValue: 0,
                monthLastValue: 0,
                yearRate: 0,
                yearValue: 0,
                yearLastValue: 0,
                weekValues: [],    
                curtMonthAimRate: 0,
                curtMonthAimValue: 0,
                curtYearAimRate: 0,
                curtYearAimValue: 0   ,   
            },
            carbon:{
                id: 0,
                title: "节能减排",
                kind: "99",
                modual: 3,
                display: false,
                hasCleanEnergy: false,
                rateLabel: "",
                rateType: "",
                unit: "kg",
                dayRate: 0,
                dayValue: 0,
                dayLastValue: 0,
                monthRate: 0,
                monthValue: 0,
                monthLastValue: 0,
                yearRate: 0,
                yearValue: 0,
                yearLastValue: 0,
                weekValues: [],
            },
            flow:{
                display: false,
                flowData: {},
                id: 0,
                kind: "0",
                modual: 0,
                title: "能源流向",
                unit: "KWh",
            },
            detail:{
                display: false,
                id: 0,
                kind: '0',
                modual: 12,
                series: [],
                title: "",
                unit: "kWh",
            },
            section:{
                display: false,
                id: 0,
                kind: '0',
                modual: 13,
                series: [],
                title: "",
                unit: "kWh",
            },
            cardSpan:12,

        }
    },
    computed: {
        yearAimId() {
            return "yearAimId" + Math.floor((Math.random() * 10000) + 1);
        },
        monthAimId() {
            return "monthAimId" + Math.floor((Math.random() * 10000) + 1);
        },
        carbonChartId() {
            return "carbonChartId" + Math.floor((Math.random() * 10000) + 1);
        },
        waterChartId(){
             return "waterChartId" + Math.floor((Math.random() * 10000) + 1);
        },
        flowChartId(){
             return "flowChartId" + Math.floor((Math.random() * 10000) + 1);
        },
        detailChartId(){
             return "detailChartId" + Math.floor((Math.random() * 10000) + 1);
        },
        peakValleyId(){
            return "peakValleyId" + Math.floor((Math.random() * 10000) + 1);
        },
        gasChartId(){
             return "gasChartId" + Math.floor((Math.random() * 10000) + 1);
        }
    },
    mounted(){
        let cardNum = 0;
        let disKPI = false;
        this.list.forEach(card => {
            if(card.kind==='1' && card.modual===1){
                this.enekind1 = card;
                this.enekind1.rateLabel = this.$t('energy.enedata610');//"实时结余";

                this.yearKPIData.series[0].data[0].value  = card.yearAimRate;
                this.monthKPIData.series[0].data[0].value = card.monthAimRate;

                this.yearKPIData.series[0].data[1].value  = this.getYearAimDayRateValue(card.kpiStarMonth);
                this.monthKPIData.series[0].data[1].value = this.getMonthAimDayRateValue();

                if(card.yearAimRate>100){
                    this.yearKPIData.series[0].data[0].itemStyle.color =  "#FF0000";
                    this.yearKPIData.series[0].data[0].itemStyle.shadowColor = "#FF0000";
                    this.yearKPIData.series[0].data[0].title.itemStyle.color =  "#FF0000";
                }
               
                if(card.monthAimRate>99){
                    this.monthKPIData.series[0].data[0].itemStyle.color =  "#FF0000";
                    this.monthKPIData.series[0].data[0].itemStyle.shadowColor = "#FF0000";
                    this.monthKPIData.series[0].data[0].title.itemStyle.color =  "#FF0000";
                }

                if(card.display){
                    cardNum += 1; 
                    disKPI = true;
                }
            } else if(card.kind==='99'){
                this.carbon =card;
                this.carbonEmissionData.series[0].data = card.weekValues;
                if(card.display)
                    cardNum+=1; 
            } else if(card.kind==='3' && card.modual===1){
                this.water = card;
                this.water.rateLabel = this.$t('energy.enedata610');//"实时结余";
                
                this.waterData.series[0].data = card.weekValues;
                 if(card.display){
                    // disKPI = true;
                    cardNum+=1; 
                 }
            } else if(card.kind==='2' && card.modual===1){
                this.gas = card;
                this.gas.rateLabel = this.$t('energy.enedata610');//"实时结余";
                this.gasData.series[0].data = card.weekValues;
                if(card.display){
                    cardNum+=1; 
                    // disKPI = true;
                }
            }else if(card.modual===4){
                this.flow = card;
                this.sankeyData.series.data = card.flowData.data;
                this.sankeyData.series.links = card.flowData.links;
            }else if(card.modual===12){
                this.detail = card;
                this.eneYieldData.series = card.series;
                this.eneYieldData.legend.data = card.legend;
                this.eneYieldData.xAxis.data = card.cates;
            }else if(card.modual===13){
                this.section = card;
                this.peakValleyData.series = card.series;
                this.peakValleyData.legend.data = card.legend;
                this.peakValleyData.xAxis.data = card.cates;
            }
        });

        this.cardSpan = 24/cardNum;
        console.log('cardSpan',this.cardSpan);
        console.log('cardSpan',this.$refs.kpichart.clientWidth);
        
        if(this.cardSpan === 8 && disKPI){
            let fontSize = 10;
            this.yearKPIData.series[0].data[0].title.offsetCenter = ['0%', '125%'];
            this.yearKPIData.series[0].data[0].detail.offsetCenter = ['0%', '160%'];
            this.yearKPIData.series[0].data[1].title.offsetCenter = ['0%', '205%'];
            this.yearKPIData.series[0].data[1].detail.offsetCenter = ['0%', '240%'];

            this.yearKPIData.series[0].detail.fontSize = fontSize;
            this.yearKPIData.series[0].detail.height = fontSize ;   
            
            this.yearKPIData.series[0].radius = '60%';
            this.yearKPIData.series[0].detail.width = 40 ;
            

            this.monthKPIData.series[0].data[0].title.offsetCenter = ['0%', '125%'];
            this.monthKPIData.series[0].data[0].detail.offsetCenter = ['0%', '160%'];
            this.monthKPIData.series[0].data[1].title.offsetCenter = ['0%', '205%'];
            this.monthKPIData.series[0].data[1].detail.offsetCenter = ['0%', '240%'];
            
            this.monthKPIData.series[0].detail.fontSize = fontSize;
            this.monthKPIData.series[0].radius = '60%';

            this.monthKPIData.series[0].detail.height = fontSize ;
            this.monthKPIData.series[0].detail.width = 40 ;


            this.yearKPIData.series[0].title.fontSize = 12;
            this.monthKPIData.series[0].title.fontSize = 12;
            this.yearKPIData.series[0].center = ['50%', '40%'];
            this.monthKPIData.series[0].center = ['50%', '40%'];

            if(this.$refs.kpichart.clientWidth<1240){
                this.yearKPIData.series[0].title.fontSize = 10;
                this.monthKPIData.series[0].title.fontSize = 10;
                this.yearKPIData.series[0].center = ['50%', '35%'];
                this.monthKPIData.series[0].center = ['50%', '35%'];

                this.yearKPIData.series[0].axisLine.lineStyle.width = 15;
                this.monthKPIData.series[0].axisLine.lineStyle.width = 15;


                this.yearKPIData.series[0].data[0].detail.offsetCenter = ['0%', '175%'];
                this.yearKPIData.series[0].data[1].title.offsetCenter  = ['0%', '240%'];
                this.yearKPIData.series[0].data[1].detail.offsetCenter = ['0%', '300%'];

                this.monthKPIData.series[0].data[0].detail.offsetCenter = ['0%', '175%'];
                this.monthKPIData.series[0].data[1].title.offsetCenter = ['0%', '240%'];
                this.monthKPIData.series[0].data[1].detail.offsetCenter = ['0%', '300%'];

            }
            // center: ['40%', '50%'],
        } else  if(this.cardSpan === 12 && disKPI){
            this.yearKPIData.series[0].title.fontSize = 12;
            this.monthKPIData.series[0].title.fontSize = 12;
        } else {

        }
        let now = new moment();
        let cats = [];
        cats[13] = now.format('YYYY/MM/DD')+"\n("+now.format('ddd')+")" ;
        for(let k=12;k>=0;k--){
            let date = now.subtract(1, 'days');
            let val =  date.format('YYYY/MM/DD')+"\n("+date.format('ddd')+")" ;
            cats[k] = val;
        }

        setTimeout(() => {

            if( this.enekind1.display){
                this.drawYearKPIChart();
                this.drawMonthKPIChart();
                if(this.cardSpan==24){
                    this.waterData.yAxis.name = this.enekind1.unit;
                    this.waterData.xAxis.data = cats;
                    this.waterData.series[0].data = this.enekind1.weekValues;
                    this.drawWaterChart();
                }
            }
            if( this.water.display){
                this.waterData.yAxis.name = this.water.unit;
                this.waterData.xAxis.data = cats;
                this.drawWaterChart();
               
                if(this.cardSpan==24){
                    this.yearKPIData.series[0].data[0].value  = this.water.yearAimRate;
                    this.monthKPIData.series[0].data[0].value = this.water.monthAimRate;
                    if(this.water.yearAimRate>100){
                        this.yearKPIData.series[0].itemStyle.color =  "#FF0000";
                        this.yearKPIData.series[0].itemStyle.shadowColor = "#FF0000";                       
                        this.yearKPIData.series[0].data[0].title.itemStyle.color =  "#FF0000";
                    }
                
                    if(this.water.monthAimRate>100){
                        console.log(this.monthKPIData.series[0]);
                        this.monthKPIData.series[0].itemStyle.color =  "#FF0000";
                        this.monthKPIData.series[0].itemStyle.shadowColor = "#FF0000";
                        this.monthKPIData.series[0].data[0].title.itemStyle.color =  "#FF0000";
                    }
                    this.drawYearKPIChart();
                    this.drawMonthKPIChart();
                }
            }
            if( this.gas.display){
                this.gasData.yAxis.name = this.gas.unit;
                this.gasData.xAxis.data = cats;
                this.drawGasChart();
               
                if(this.cardSpan==24){
                    this.yearKPIData.series[0].data[0].value  = this.gas.yearAimRate;
                    this.monthKPIData.series[0].data[0].value = this.gas.monthAimRate;
                    if(this.gas.yearAimRate>100){
                        this.yearKPIData.series[0].itemStyle.color =  "#FF0000";
                        this.yearKPIData.series[0].itemStyle.shadowColor = "#FF0000";
                        this.yearKPIData.series[0].data[0].title.itemStyle.color =  "#FF0000";
                    }
                
                    if(this.gas.monthAimRate>100){
                        this.monthKPIData.series[0].itemStyle.color =  "#FF0000";
                        this.monthKPIData.series[0].itemStyle.shadowColor = "#FF0000";
                        this.monthKPIData.series[0].data[0].title.itemStyle.color =  "#FF0000";
                    }
                    this.drawYearKPIChart();
                    this.drawMonthKPIChart();
                }
            }

            if( this.carbon.display && !this.carbon.hasCleanEnergy){
                this.carbonEmissionData.xAxis.data = cats;
                
                this.drawCarbonEmissionChart();
            }
            if( this.detail.display ){
                this.eneYieldChart();
            }
            if( this.flow.display ){
                this.drawSankeyChart();
            }

            this.drawPeakValleyChart();
        }, 100);
        
       
        // this.drawWaterKPIChart()
        // this.drawSankeyChart()
        // this.drawPeakValleyChart();
        // this.waterYieldChart()
    },
    methods:{
        getYearAimDayRateValue(star){
            let now = new moment();

            let smon = star - 1;
            let syear = now.year();
            if(now.month() < smon){
                syear = syear-1;
            }
            let starDate = new moment(syear+"-"+star+"-1");
            let endDate = new moment((syear+1)+"-"+star+"-1");
            let num = now.diff(starDate,'day')+1;
            let sum = endDate.diff(starDate,'day');
            console.log('sum:',sum);
            console.log('num:',num);
            return Number(num/sum*100).toFixed(2);
        },
        getMonthAimDayRateValue(){
            let now = new moment();
            let num = now.date();
            let sum = now.daysInMonth();
            console.log('sum:',sum);
            console.log('num:',num);
            return Number(num/sum*100).toFixed(2);
        },
        // 绘画年度KPI的图表
        drawYearKPIChart() {
            let myChart = this.$echarts.init(document.getElementById(this.yearAimId));
            let resizeDiv = document.getElementById(this.yearAimId);
            console.log("drawYearKPIChart yearKPIData:",this.yearKPIData);
            myChart.setOption(this.yearKPIData);
            let listener = () => {
                myChart.resize();
            };
            console.log("drawYearKPIChart myChart:",myChart);
            EleResize.on(resizeDiv, listener);
        },
        // 绘画月度KPI的图表
        drawMonthKPIChart() {
            let myChart = this.$echarts.init(document.getElementById(this.monthAimId));
            let resizeDiv = document.getElementById(this.monthAimId);
            myChart.setOption(this.monthKPIData);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },

         // 绘每日碳排放的图表
        drawCarbonEmissionChart() {
            let myChart = this.$echarts.init(document.getElementById(this.carbonChartId));
            let resizeDiv = document.getElementById(this.carbonChartId);
            myChart.setOption(this.carbonEmissionData);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        // 绘画每周用水量的图表
        drawWaterChart(){
            let myChart = this.$echarts.init(document.getElementById(this.waterChartId));
            let resizeDiv = document.getElementById(this.waterChartId);
            myChart.setOption(this.waterData);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        // 绘画每日用气量的图表
        drawGasChart(){
            let myChart = this.$echarts.init(document.getElementById(this.gasChartId));
            let resizeDiv = document.getElementById(this.gasChartId);
            myChart.setOption(this.gasData);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        // 绘画月度电能流向的图表
        drawSankeyChart(){
            let myChart = this.$echarts.init(document.getElementById(this.flowChartId));
            let resizeDiv = document.getElementById(this.flowChartId);
            myChart.setOption(this.sankeyData);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        // 峰谷分析柱状图
        drawPeakValleyChart(){
            let myChart = this.$echarts.init(document.getElementById(this.peakValleyId));
            let resizeDiv = document.getElementById(this.peakValleyId);
            myChart.setOption(this.peakValleyData);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        // 用电明细对比折线图
        eneYieldChart(){
            let myChart = this.$echarts.init(document.getElementById(this.detailChartId));
            let resizeDiv = document.getElementById(this.detailChartId);
            myChart.setOption(this.eneYieldData);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        }
    }
}
</script>

<style scoped>
#globalFunction {
        font-size: 40%!important; /* 10÷16=62.5% */
}
@media only screen and (min-width: 1240px){
    #globalFunction {
        font-size: 42.5%!important; /* 10÷16=62.5% */
    }
}

@media only screen and (min-width: 1440px){
    #globalFunction {
        font-size: 48.5%!important; /* 10÷16=62.5% */
    }
}
@media only screen and (min-width: 1900px){
    #globalFunction {
        font-size: 62.5%!important; /* 10÷16=62.5% */
    }
}
#globalFunction{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-family: "Microsoft YaHei";
}
.card-container{
    display: flex;
    justify-content: space-between;
    height: 2.1rem;
}
.echarts-container{
    height: calc(100% - 2.3rem);
}

.frame{
    height: 100%;
    border-radius: 0.03rem;
    background-color: #fff;
    box-shadow: 0 0px 10px #bdbcbc;
    overflow: hidden;
}
.card-title{
    display: flex;
    align-items: center;
    height: 0.35rem;
    font-size: 0.14rem;
    padding: 0px 0.1rem;
}
.card-title-img{
    width: 0.25rem;
    height: 0.25rem;
    margin-right: 0.1rem;
}
/* calc(35% - 0.05rem); */
.electricity{
    width: 100%;
    height: 2.2rem;
}
.water{
     width: 100%;
     height: 2.2rem;
}
.gas{
     width: 100%;
     height: 2.2rem;
}
.carbon{
     width: 100%;
     height: 2.2rem;
}
.card-main{
    display: flex;
    height: calc(100% - 0.4rem);
    width:100%;
    padding-left: 0.15rem;
}
.card-main2{
    width: 100%;
    height: calc(100% - 0.4rem);
    padding: 0 0.12rem;
}
.card-main2-item-title{
    width: 100%;
    font-size: 0.14rem;
    white-space: nowrap;
}
.card-main2-item-content{
    width: 100%;
}
.card-font-info-4-left{
    width: 100%;
    margin-right: 0.05rem;
}
.card-font-info-4{
    width: 100%;
    margin-left: 0.05rem;
}

.card-font-info{
    width: 2.0rem;
    margin-right: 0.05rem;
}
.card-font-info-3{
    width: 2.0rem;
    margin-right: 0.05rem;
}
.card-row{
    margin-bottom: 0.05rem;
}
.card-row-top-4{
    font-size: 0.12rem;
    color: #a0a0a0;
    margin: 0.01rem 0;
    white-space: nowrap;
}
.card-row-top{
    font-size: 0.12rem;
    color: #a0a0a0;
    margin: 0.02rem 0;
    white-space: nowrap;
}
.card-row-bottom{
    font-size: 0.14rem;
    color: #3e3d3b;
    text-align: right;
}
.card-row-bottom-text{
    font-size: 0.12rem;
    color: #3e3d3b;
    text-align: right;
}
.chart{
    display: flex;
    width: calc(100% - 2rem);
    border-left: 1px solid #e8e8e8;
}
.chart-yearKPI{
    width: 50%;
    height: 100%;
}
.chart-monthKPI{
    width: 50%;
    height: 100%;
}
.carbonEmission{
    width: calc(50% - 0.105rem); 
}
.carbonEmission-title{
    font-size: 0.12rem;
    font-weight: 600;
}
.carbonEmission-row{
    margin-bottom: 0.05rem;
}
.line{
    height: 100%;
    width: 100%;
    margin: 0rem 0.1rem;
}
.sankey-container{
    /* width: calc(50% - 0.05rem); */
    height: 100%;
    border-radius: 0.03rem;
    background-color: #fff;
    box-shadow: 0 0px 10px #bdbcbc;
    margin-top: 0.1rem;
    overflow: hidden;
}
 .compartment{
    height: 49%;
    margin-top: 0.1rem;
} 

.compartment-container{
    height: 100%;
    border-radius: 0.03rem;
    background-color: #fff;
    box-shadow: 0 0px 10px #bdbcbc;
    overflow: hidden;
}
.container-content{
     height: calc(100% - 0.35rem);
}
</style>